import axios from "axios";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IdiomContext } from "../../../context/createcontext";
import UserService from "../../../services/UserService";
import DropdownFilter from "./DropdownFootprintFilter";



export default function ModalTableSelectionConcept({ conceptSelectionTableView, updateModalTableView, idCalculation, updateSelectedConceptTableView, idQuestion }) {

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const [conceptTableView, setConceptTableView] = useState({ data: [], isFetching: false });
    const [lineasTabla, setLineasTabla] = useState([]);
    const [filtros, setFiltros] = useState({
        text: "",
        factors1: [],
        factors2: []
    });
    const [uniqueConceptList1, setUniqueConceptList1] = useState([]);
    const [uniqueConceptList2, setUniqueConceptList2] = useState([]);


    const fetchTableView = async () => {

        const url = `${process.env.REACT_APP_CH_QUESTIONNAIRE}/calculation/${idCalculation}/tableview?idInput=${conceptSelectionTableView.idInput}`;
        try {
            setConceptTableView({ data: conceptTableView.data, isFetching: true });
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setConceptTableView({ data: response.data.data, isFetching: false });
            setLineasTabla(response.data.data.options);
        } catch (exception) {
            setConceptTableView({ data: [], isFetching: false });
        }
    }

    useEffect(() => {

        if (conceptTableView.data?.uniqueOptions1 != undefined) {
        
            let data =  conceptTableView.data;

            const uniqueArray1 = [];
            data.uniqueOptions1.forEach((item, index) => {
                uniqueArray1.push({ id: index, name: item, check: false });
            });

            setUniqueConceptList1(uniqueArray1);

            if(data.uniqueOptions2 != undefined){
                const uniqueArray2 = [];
                data.uniqueOptions2.forEach((item, index) => {
                    uniqueArray2.push({ id: index, name: item, check: false });
                });
                setUniqueConceptList2(uniqueArray2);
            }
        }

    }, [conceptTableView]);

    useEffect(() => {
        if (conceptSelectionTableView.idInput != null) {
            fetchTableView();
        }
    }, [conceptSelectionTableView])

    const OnFilterText = (filter, filteredData) => {
        return filteredData.filter((v) => {
            const values = Object.values(v || {});
            return values.some(value => {
                if (typeof value === 'string' || typeof value === 'number') {
                    return String(value).toLowerCase().includes(filter.toLowerCase());
                }
                return false;
            }
            );
        });
    };

    useEffect(() => {
        const handleInputChange = () => {
            let filteredData = conceptTableView.data.options;

            if (filtros.text) {
                filteredData = OnFilterText(filtros.text, filteredData);
            }

            if (filtros.factors1.length > 0) {
                filteredData = OnFilterFactors1(filtros.factors1, filteredData);
            }

            if (filtros.factors2.length > 0) {
                filteredData = OnFilterFactors2(filtros.factors2, filteredData);
            }

            setLineasTabla(filteredData);
        };
        handleInputChange();
    }, [filtros]);

    const OnFilterFactors1 = (filter, filteredData) => {
        
        const filtrosActivos = new Set(filter.filter(f => f.check).map(f => f.name));
        if (filtrosActivos.size > 0) {
            return filteredData.filter(dato => filtrosActivos.has(conceptTableView.data.columnNumber > 2 || idQuestion == 57 ? dato.select2Name : dato.select1Name));
        }
        return filteredData;
    };

    const OnFilterFactors2 = (filter, filteredData) => {
        const filtrosActivos = new Set(filter.filter(f => f.check).map(f => f.name));
        if (filtrosActivos.size > 0) {
            return filteredData.filter(dato => filtrosActivos.has(conceptTableView.data.columnNumber > 2 ? dato.select3Name : dato.select2Name));
        }
        return filteredData;
    };

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!Array.isArray(items)) return [];
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    const valueA = a[sortConfig.key];
                    const valueB = b[sortConfig.key];

                    // Handle undefined, null, and empty strings 
                    const aIsEmpty = valueA === undefined || valueA === null || valueA === '';
                    const bIsEmpty = valueB === undefined || valueB === null || valueB === '';

                    if (aIsEmpty && !bIsEmpty) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (bIsEmpty && !aIsEmpty) return sortConfig.direction === 'ascending' ? 1 : -1;
                    if (aIsEmpty && bIsEmpty) return 0; // Both empty, treat as equal

                    // Numerical comparison
                    if (!isNaN(valueA) && !isNaN(valueB)) {
                        return sortConfig.direction === 'ascending' ? valueA - valueB : valueB - valueA;
                    }

                    // String comparison (case-insensitive)
                    return sortConfig.direction === 'ascending'
                        ? valueA.toString().localeCompare(valueB.toString(), undefined, { sensitivity: 'base' })
                        : valueB.toString().localeCompare(valueA.toString(), undefined, { sensitivity: 'base' });
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(lineasTabla);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };


    const handleSelectConcept = (selectedRowData) => {
        updateSelectedConceptTableView(selectedRowData);
        updateModalTableView({ view: false, idInput: conceptSelectionTableView.idInput })
    }


    const setApplyFactors1Filter = (data) => {
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            factors1: data,
        }));
    };

    const setApplyFactors2Filter = (data) => {
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            factors2: data,
        }));
    };

    return (
        <Modal show={conceptSelectionTableView.view} size="lg" style={{ maxHeight: '90vh', overflowY: 'hidden' }}>
            <ModalHeader className='sec-neutro-background'>
                <ModalTitle className="font-semibold sec-grey-text">Selecciona la Fuente de Emisión</ModalTitle>
            </ModalHeader>
            <ModalBody style={{ height: '70vh', overflowY: 'hidden' }}>
                <div>

                    {(conceptTableView.data.options != null && !conceptTableView.isFetching) &&
                        <div  style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}>
                            <div className="flex  flex-wrap md:flex-nowrap justify-between mt-1 gap-1">
                                <div className="flex flex-wrap gap-1">
                                    <DropdownFilter
                                        uniqueValues={uniqueConceptList1}
                                        applyFilter={setApplyFactors1Filter}
                                        nombreFiltro={conceptTableView.data.columnNumber > 2 || idQuestion == 57 ? conceptTableView.data.select2Title : conceptTableView.data.select1Title}
                                    />
                                    {uniqueConceptList2.length > 0 &&
                                        <DropdownFilter
                                            uniqueValues={uniqueConceptList2}
                                            applyFilter={setApplyFactors2Filter}
                                            nombreFiltro={conceptTableView.data.columnNumber > 2 ? conceptTableView.data.select3Title : conceptTableView.data.select2Title}
                                            />                           
                                    }
                                </div>
                                <div className="search-container" >
                                    <input
                                        value={filtros.text}
                                        className='text-xs'
                                        style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                        onChange={(e) =>
                                            setFiltros((prevFiltros) => ({
                                                ...prevFiltros,
                                                text: e.target.value,
                                            }))
                                        }
                                        placeholder={t("footPrint.wordSearch")}
                                        type="text"
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                        <path d="M21 21l-6 -6" />
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <p className="text-xs">Haz click en una de las filas para seleccionarla como fuente de emisión</p>
                            </div>
                        </div>
                    }

                    {conceptTableView.data.options == null ?
                        <div className="flex justify-center items-center gap-2">
                            {conceptTableView.isFetching ?
                                <><span className="text-sm">Cargando fuentes de emsisión</span> <Spinner animation="border" variant="dark" /> </> :
                                "No hay datos para esta pregunta."
                            }
                        </div>
                        :
                        <div className="container-simple-table-sm mt-2" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                            <table className="simple-table-sm">
                                <thead className="text-center text-white sec-green-background" style={{ position: 'sticky', top: 0, zIndex: 5 }}>
                                    <tr>
                                        <th>
                                            <button type="button" onClick={() => requestSort("select1Name")}
                                                className={`${getClassNamesFor("select1Name")} `}>
                                                {conceptTableView.data.select1Title}
                                            </button>
                                        </th>
                                        <th>
                                            <button type="button" onClick={() => requestSort("select2Name")}
                                                className={`${getClassNamesFor("select2Name")} `}>
                                                {conceptTableView.data.select2Title}
                                            </button>
                                        </th>
                                        {conceptTableView.data.columnNumber > 2 &&
                                            <th>
                                                <button type="button" onClick={() => requestSort("select3Name")}
                                                    className={`${getClassNamesFor("select3Name")} `}>
                                                    {conceptTableView.data.select3Title}
                                                </button>
                                            </th>
                                        }
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {(conceptTableView.data.options != null && items.length > 0) ?
                                        items.map((x, index) => (
                                            <tr key={index} onClick={() => handleSelectConcept(x)} className="fuente-emision-table-select" >
                                                <td>{x.select1Name}</td>
                                                <td>{x.select2Name}</td>
                                                {conceptTableView.data.columnNumber > 2 && <td>{x.select3Name}</td>}
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={3}>No hay datos para la busqueda realizada</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter className="flex justify-center">
                <button className="btn-sm btn-cancel rounded" onClick={() => updateModalTableView({ view: false, idInput: null })}>
                    {t("fpThird.cerrar")}
                </button>
            </ModalFooter>
        </Modal>

    )

}