import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { IdiomContext } from "../../../context/createcontext";
import { HashLink } from "react-router-hash-link";

export default function Term({ handleUpdateName }) {
    const pathParams = useParams();
    const termslug = pathParams.term;

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");

    const [term, setTerm] = useState({ data: undefined, isFetching: false });



    useEffect(() => {
        const fetchTerm = async () => {
            const url = `${process.env.REACT_APP_PUBLIC_TERM}/${termslug}?lng=${lng}`
            try {
                setTerm({ data: term.data, isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

                setTerm({ data: response.data.data, isFetching: false });
                handleUpdateName(response.data.data.title)
            } catch (exception) {
                setTerm({ data: [], isFetching: false });
                console.error('Error al realizar la solicitud:', exception);
            }
        };

        fetchTerm();
    }, [lng]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<>


        <div>
            <section className="relative">
                <div className="absolute inset-0 rounded-bl-[100px] bg-gray-50 pointer-events-none -z-10" aria-hidden="true" />
                <div className="flex items-center h-full header-glossary-top overflow-hidden">
                    {!term.data && term.isFetching &&
                        <div className="relative max-w-6xl mx-auto px-4 sm:px-6 text-center md:text-left flex flex-col items-center" >
                            <Spinner animation="border" variant="dark"></Spinner>
                            {t("glossary.charging")}</div>
                    }
                    {term.data && !term.isFetching &&
                        <div className="relative max-w-6xl mx-auto px-4 sm:px-6 text-center md:text-left flex flex-col items-center">
                            <div className="home-introduction-h1">
                                <h1 className="h1 font-cabinet-grotesk mb-6 mt-4 md:mt-0" data-aos="fade-right" data-aos-delay="100">
                                    {term.data.title}
                                </h1>
                            </div>
                            <div className="text-xs " data-aos="fade-up" data-aos-delay="100">
                                <HashLink
                                    aria-label="apartado resources"
                                    className="text-airco2-principal flex items-center"
                                    to={`/resources/glossary`}
                                    smooth={true}
                                    offset={50}
                                    duration={500}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="1">
                                        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1"></path>
                                    </svg>
                                    <span className="ml-2">{t("glossary.return")}</span>
                                </HashLink>
                            </div>
                        </div>
                    }
                </div>
            </section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 m-4" data-aos="fade-down" data-aos-delay="200">
                <p dangerouslySetInnerHTML={{ __html: term.data?.definition }} />
            </div>
        </div>


    </>)
}