import axios from "axios";
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import { IdiomContext } from "./../../../../context/createcontext";

Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function EvolutionChart() {
    const [lng, updateLng] = useContext(IdiomContext)
    const canvas = useRef(null);
    const legend = useRef(null);
    const [t, i18n] = useTranslation("global");


    const [calculationEvolutions, setCalculationEvolutions] = useState({ labels: undefined, scope1: undefined, scope2: undefined, scope3: undefined, isFetching: false });

    useEffect(() => {
        const fetchCalculationEvolutions = async () => {
            const url = `${process.env.REACT_APP_CH_EVOLUTIONS}`;

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                const groupDataByYear = (data) => {
                    return data.reduce((acc, curr) => {
                        const year = curr.year;
                        const totalKgCO2eScope1 = curr.totalKgCO2eScope1 || 0;
                        const totalKgCO2eScope2 = curr.totalKgCO2eScope2 || 0;
                        const totalKgCO2eScope3 = curr.totalKgCO2eScope3 || 0; 

                        if (!acc[year]) {
                            acc[year] = { totalKgCO2eScope1: 0, totalKgCO2eScope2: 0, totalKgCO2eScope3: 0 };
                        }

                        acc[year].totalKgCO2eScope1 += totalKgCO2eScope1;
                        acc[year].totalKgCO2eScope2 += totalKgCO2eScope2;
                        acc[year].totalKgCO2eScope3 += totalKgCO2eScope3;

                        return acc;
                    }, {});
                };

                // Procesamos los datos recibidos para agruparlos
                const groupedData = groupDataByYear(response.data.data);

                // Extraemos los valores agrupados para setear el estado
                const years = Object.keys(groupedData);
                const scope1 = years.map(year => groupedData[year].totalKgCO2eScope1);
                const scope2 = years.map(year => groupedData[year].totalKgCO2eScope2);
                const scope3 = years.map(year => groupedData[year].totalKgCO2eScope3);


                setCalculationEvolutions({ labels: years, scope1: scope1, scope2: scope2, scope3: scope3, isFetching: true })
            } catch (exception) {
                setCalculationEvolutions({ id: undefined, isFetching: true })
                console.error(exception);
            }
        };

        fetchCalculationEvolutions();
    }, []);




    useEffect(() => {
        if (!calculationEvolutions?.isFetching) return;

        const dataFormateada = calculationEvolutions?.labels?.map((year, index) => ({
            year: year,
            alcance1: calculationEvolutions.scope1[index],
            alcance2: calculationEvolutions.scope2[index],
            alcance3: calculationEvolutions.scope3[index],
        }));


        const scope1Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope1');
        const scope1LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope1-light');


        const scope2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope2');
        const scope2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope2-light');

        const scope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3');
        const scope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-light');

        const data = {
            labels: dataFormateada?.map(item => item.year),
            datasets: [
                {
                    label: t("dash.alcance1"),
                    data: dataFormateada?.map(item => item.alcance1),
                    backgroundColor: scope1Color,
                    hoverBackgroundColor: scope1LightColor,
                    stack: 'a',
                    borderWidth: 1,
                },
                {
                    label: t("dash.alcance2"),
                    data: dataFormateada?.map(item => item.alcance2),
                    backgroundColor: scope2Color,
                    hoverBackgroundColor: scope2LightColor,
                    stack: 'a',
                    borderWidth: 1,
                },
                {
                    label: t("dash.alcance3"),
                    data: dataFormateada?.map(item => item.alcance3),
                    backgroundColor: scope3Color,
                    hoverBackgroundColor: scope3LightColor,
                    stack: 'b',
                    borderWidth: 1,
                },
            ],
        };


        const ctx = canvas.current;
        // eslint-disable-next-line no-unused-vars
        switch (lng) {
            case "en":
                moment.locale("en");
                break;
            case "pt":
                moment.locale("pt");
                break;
            case "gl":
                moment.locale("gl");
                break;
            default:
                moment.locale("es");
                break;
        }
        const chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart - Stacked'
                    },

                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const dataset = context.dataset;
                                const valorFormateado = `${formatThousands(context.parsed.y)} kgCO₂e`;
                                return `${dataset.label}: ${valorFormateado}`;
                            },
                        },
                    },

                    legend: {
                        display: true,
                    },
                },
                // maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'category',
                    },
                    y: {
                        stacked: true
                    }
                }
            }

        });

        return () => chart.destroy();
        // eslint-disable-next-line
    }, [lng, calculationEvolutions]);


    return (
        <React.Fragment>
            {!calculationEvolutions?.isFetching && <div className="text-center"><Spinner className='' /></div>}
            {!canvas !== null && calculationEvolutions?.isFetching && calculationEvolutions?.labels?.length > 0 && <>
                <h3 className="text-center font-bold mb-2">{t("evolution.infoAlcance")}</h3>
                <canvas ref={canvas} style={{ maxHeight: "350px" }}></canvas></>}
            {!canvas !== null && calculationEvolutions?.isFetching && calculationEvolutions?.labels?.length === 0 && <p className="text-center">
                Para ver esta gráfica primero es necesario que finalices algún cálculo.</p>}
        </React.Fragment>
    );


}